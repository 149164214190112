import React from "react";
import { graphql, withPrefix } from "gatsby";
import { Helmet } from "react-helmet";

import parse from "html-react-parser";
import Img from "gatsby-image";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import { getStringDate } from "utils/formats";

import Layout from "components/layout";
import { GeneralOpenGraphMeta } from "components/generalOpenGraphMeta";
import SEO from "components/seo";

const ArticleTemplate = ({ data }) => {
  const { strapiArticle } = data;
  const {
    title,
    thumbnailImage,
    images,
    contentText,
    originallyCreatedAt,
    createdAt,
  } = strapiArticle;

  return (
    <Layout>
      <div className="page-article">
        <SEO />
        <Helmet>
          <GeneralOpenGraphMeta />
        </Helmet>
        <div className="right-side-container">
          <span className="site-underline"></span>

          <div className="article-photo-container mobile">
            <div className="main-image">
              <div className="image-wrap">
                <img
                  src={thumbnailImage.childImageSharp.fluid.src}
                  alt="Vygenerovaný alt tag"
                  className="image"
                  id="main-image-mobile"
                />
              </div>
            </div>
          </div>

          <div className="content-padding">
            <section className="article-text-container">
              <header className="header">
                <h1 className="heading">{title}</h1>
              </header>

              <span className="section-underline"></span>

              <div>
                {contentText && (
                  <section className="article-text-container text-container">
                    {parse(contentText)}
                  </section>
                )}
              </div>

              <section>
                <span className="date-text">
                  {getStringDate(
                    originallyCreatedAt ? originallyCreatedAt : createdAt
                  )}
                </span>
              </section>
            </section>
          </div>
        </div>
        <SimpleReactLightbox>
          <div className="left-side-container">
            <section className="article-photo-container desktop runGallery">
              <div className="main-image">
                <div className="image-wrap">
                  <Img
                    fluid={thumbnailImage.childImageSharp.fluid}
                    alt="recept"
                    className="image"
                  />
                </div>
              </div>
              <SRLWrapper>
                <div className="thumbnail-images-wrap">
                  {images.map((img) => (
                    <div key={img.id} className="image-wrap" target="_blank">
                      <a href={img.url} data-attribute="SRL">
                        <img
                          src={img.url}
                          data-src={img.url}
                          className="image"
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </SRLWrapper>
            </section>
          </div>

          <span className="site-underline site-underline--bottom"></span>
        </SimpleReactLightbox>
      </div>
    </Layout>
  );
};

export default ArticleTemplate;

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: { eq: $id }) {
      id
      title
      contentText
      createdAt
      originallyCreatedAt
      thumbnailImage {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      images {
        id
        url
      }
    }
  }
`;
